<template>
  <div>
    <el-form :inline="true">
      <el-form-item>
        <el-select v-model="searchObj.park" placeholder="请选择园区" class="centerSelInput">
          <el-option v-for="item in parkList" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-input v-model="searchObj.vehicleNumber" placeholder="请输入车辆编号" class="centerInput "></el-input>
      </el-form-item>

      <el-form-item>
        <el-date-picker v-model="searchObj.date" type="week" class="centerInput" format="yyyy 第 WW 周"
          placeholder="请选择日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <button class="centerCzbtn ">查询</button>
      </el-form-item>



    </el-form>
    <div class="todayPlan">
      <div>
        <div>今日总计划数： <span class="three3">0</span> </div>
        <div>在线车辆数： <span class="three3">0</span> </div>
      </div>
      <div>
        <div>当前时间： <span class="three3">2021.08.17 11:29:28</span> </div>
        <div>当前排班计划： <span class="three3">0</span> </div>
        <div>实际运营车辆： <span class="three3">0</span> </div>
      </div>
    </div>
    <div class="slide">
      <el-slider v-model="value" range show-stops :max="10">
      </el-slider>
      <div class="time">
        <span>2020.02.14</span><span>2020.02.15</span>
      </div>
      <div class="vehNumber">
        <div>车辆编号：<span class="three3"> G3502</span></div>
        <div>车辆类型：<span class="three3"> Sharing-VAN 1.0Plus</span></div>
        <div>运营模式：<span class="three3">通勤运营</span></div>
      </div>
    </div>
    <div class="lineCon">
      <div class="line">
        <div>线路：1号线</div>
        <div>线路：1号线</div>
      </div>
      <div class="plan">
        <div class="planBefore"></div>
        <span>计划</span>
        <div class="planBlue1">> > > > > > > > > > > >> > > > > ></div>
        <div class="planBlue2">> > > > > > > > > > > >> > > > > ></div>
      </div>
      <div class="actual">
        <div class="planBefore"></div>
        <span>实际</span>
        <div class="ringBlue"></div>
        <div class="planYellow1">> > > > > > > > > > > >> > > > > ></div>
        <div class="ringRed"></div>
        <div class="planYellow2">> > > > > > > > > > > >> > > > > ></div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        searchObj: {
          park: "",
          vehicleNumber: "",
          date: ""
        },
        parkList: [{
            value: 0,
            label: "园区1"
          },
          {
            value: 1,
            label: "园区2"
          },
          {
            value: 2,
            label: "园区3"
          }
        ]
      };
    },
  }
</script>

<style scoped>
  .todayPlan {
    height: 36px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0px 12px;
    color: #666666;
    font-size: 14px;
  }

  .three3 {
    color: #333;
    font-size: 14px;
    font-weight: 550;
  }

  .todayPlan>div {
    display: flex;
    align-items: center;
  }

  .todayPlan>div>div:last-child,
  .vehNumber>div:last-child {
    margin-left: 40px;
  }

  .todayPlan>div>div:nth-child(2),
  .vehNumber>div:nth-child(2) {
    margin-left: 40px;
  }

  .vehNumber {
    display: flex;
    height: 36px;
    background: #F5F8FA;
    line-height: 36px;
    font-size: 14px;
    color: #666;
  }

  .time {
    background-color: #fff;
    height: 65px;
    line-height: 65px;
    font-size: 12px;
    color: #999;
  }

  .time>span:first-child {
    margin-left: 70px;
  }

  .time>span:last-child {
    margin-left: 500px;
  }

  .lineCon {
    background-color: #fff;
  }

  .line {
    font-size: 12px;
    color: #666;
    display: flex;
    justify-content: space-around;
    padding: 20px 0px 11px;

  }

  .actual {
    margin-top: 16px;
  }

  .plan,
  .actual {
    height: 28px;
    border-top: 1px solid #D4D7D9;
    border-bottom: 1px solid #D4D7D9;
    position: relative;
    font-size: 12px;
    color: #666;
    line-height: 28px;
    display: flex;
  }

  .planBefore {
    width: 1px;
    height: 14px;
    background: #459CE0;
    position: absolute;
    left: 0px;
    top: 9px;
  }

  .plan span,
  .actual span {
    margin-left: 10px;
  }

  .planBlue1,
  .planBlue2 {
    background-color: #1683D9;
    color: white;
    overflow: hidden;
  }

  .planYellow1,
  .planYellow2 {
    background-color: #D0844D;
    color: white;
    overflow: hidden;
    position: absolute;
    margin-left: 125px;
  }

  .planYellow2 {
    position: absolute;
    right: 0px;
  }

  .planBlue1 {
    margin-left: 90px;
  }

  .planBlue2 {
    position: absolute;
    right: 0px;
  }
</style>